import {Component, EventEmitter, Output, Input, ViewChild, OnInit, ElementRef, TemplateRef, Inject} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';
import {Router} from '@angular/router';
import {DataService, Response} from '../../dataservice/data.service';
import {GlobalVariable} from '../../app.component';
import {ToastrService} from 'ngx-toastr';
import {CommonService} from '../../services/common.service';
import {FormGroup, FormControl, Validators, FormBuilder, NgForm, FormsModule, ReactiveFormsModule} from '@angular/forms';
import _ from 'lodash';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  private BASE_API_URL = GlobalVariable;
  managerAccess: any = [];
  userDetails: any;
  public show: boolean = false;
  avatorName: any;
  // branchname:any;
  branchForm: FormGroup;
  brid: any;
  currentSelectedMenu: any;

  constructor(private modalService: NgbModal, @Inject(LOCAL_STORAGE) private storage: WebStorageService,
              private router: Router, private HttpService: DataService, public formBuilder: FormBuilder,
              private toastrService: ToastrService, private commonservice: CommonService) {
  }

  ngOnInit() {
    this.managerAccess = this.storage.get('contact_access');
    this.brid = this.storage.get('select_branch');
    this.currentSelectedMenu = this.router.url === "/dashboard";
    if(!this.brid) {
      this.brid = this.managerAccess[0].building_id.id;
      this.storage.set('select_branch', this.brid);
    }
    this.branchForm = new FormGroup({
      'branchname': new FormControl(this.brid, [
        Validators.required,
      ])
    });
    this.commonservice.changeBranch(this.brid);
    this.HttpService.get(this.BASE_API_URL.MANAGER_BUILDINGS).subscribe(res => {
      const managerBuildings: any = res['data'];
      let managerBuildingIds = _.sortBy(_.map(managerBuildings, 'building_id.id'));
      let managerAccessIds = _.sortBy(_.map(this.managerAccess, 'building_id.id'));
      if(! _.isEqual(managerBuildingIds, managerAccessIds)) {
        this.logout();
      }
    }, (err) => {
      this.toastrService.error(err.error.message);
    });
    this.userDetails = this.storage.get('user_details');
    this.avatorName = this.userDetails.name.substring(0, 2);
    this.commonservice.userDetail.subscribe(res => {
      if (res !== null) {
        this.userDetails = res;
        this.avatorName = this.userDetails.name.substring(0, 2);
      }
    });
  }

  /**
   * Signout and redirect to sign in page
   * @memberof HeaderComponent
   */
  logout() {
    this.storage.remove('con_access_token');
    this.storage.remove('user_details');
    this.storage.remove('contact_access');
    this.storage.remove('select_branch');
    this.storage.remove('checkinchecklist');
    this.storage.remove('student_id');
    this.router.navigate(['/login']);
  }

  public toggle(): void {
    this.show = !this.show;
    this.commonservice.sideMenuBar(this.show);
  }

  profileClick() {
    this.commonservice.profile(true);
  }

  changebranch() {
    this.branchForm.patchValue({'branchname': this.branchForm.value.branchname});
    this.commonservice.changeBranch(this.branchForm.value.branchname);
    this.storage.set('select_branch', this.branchForm.value.branchname);
  }

}
