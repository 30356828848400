import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './core';
import { AuthLayoutComponent } from './core';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';

export const AppRoutes: Routes = [
   { path: '', redirectTo: '/maintenance', pathMatch: 'full' },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './account/account.module#AccountModule'
      },
      {
        path: 'account',
        loadChildren: './account/account.module#AccountModule'
      }
    ]
  },

  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard] ,
    children: [
      {
        path: 'maintenance',
        loadChildren: './maintenance/maintenance.module#MaintenanceModule'
      },
      {
        path: 'residents',
        loadChildren: './resident/resident.module#ResidentModule'
      },
      {
        path: 'applicants',
        loadChildren: './applicant/applicant.module#ApplicantModule'
      },
      {
        path: 'ticketdetail',
        loadChildren: './ticketdetail/ticketdetail.module#TicketdetailModule'
      },
      {
        path: 'allocateroom',
        loadChildren: './allocateroom/allocateroom.module#AllocateroomModule'
      },
      {
        path: 'applicants-profile',
        loadChildren: './checkinprofile/checkinprofile.module#CheckinprofileModule'
      },
      {
        path: 'add-applicant',
        loadChildren: './addapplicant/addapplicant.module#AddapplicantModule'
      },
      {
        path: 'resident-process',
        loadChildren: './residentprocess/residentprocess.module#ResidentprocessModule'
      },
      {
        path: "inspection",
        loadChildren: "./inspection/inspection.module#InspectionModule",
      },
      /*{
        path: 'payments',
        loadChildren: './payment/payment.module#PaymentModule'
      },*/
      {
        path: "waitinglist",
        loadChildren:
          "./waitlist-configuration/waitlist-configuration.module#WaitinglistConfigurationModule",
      },
      {
        path: 'waitingdetails',
        loadChildren: './waitingdetails/waitingdetails.module#WaitingdetailsModule'
      },
      {
        path: 'roomavailable',
        loadChildren: './roomavailabale/roomavailabale.module#RoomavailableModule'
      },
      {
        path: 'profile',
        loadChildren: './profile/profile.module#ProfileModule'
      },
      {
        path: 'notice-board',
        loadChildren: './notice-board/notice-board.module#NoticeBoardModule'
      },
      {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'visitors-log',
        loadChildren: './visitors-log/visitors-log.module#VisitorsLogModule'
      },
      { path: '**', redirectTo: '/login' }
    ]
  }
];

