import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AuthService } from '../app/auth/auth.service';
import { Router, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PlatformLocation } from '@angular/common';
import { CommonService } from '../app/services/common.service';
import { PusherService } from '../app/services/pusher/pusher.service';
import { LOCAL_STORAGE,  WebStorageService } from 'angular-webstorage-service';
import { Spinkit } from 'ng-http-loader';
import { Title } from '@angular/platform-browser';

export interface Options {
  heading?: string;
  removeFooter?: boolean;
}
@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})

export class AppComponent implements OnInit {
  public spinkit = Spinkit;
  routeOptions: Options;
  constructor(private location: Location, private locationredirect: PlatformLocation, private commonservice: CommonService,
              translate: TranslateService, private titleService: Title, public auth: AuthService, private router: Router,
              private route: ActivatedRoute, private pusherService: PusherService,
    @Inject(LOCAL_STORAGE) private storage:  WebStorageService) {
    locationredirect.onPopState(() => {
      const route_titles = this.location.path().split('/');
      const route_params = route_titles[1].charAt(0).toLocaleUpperCase() + route_titles[1].slice(1);
      if (this.auth.isAuthenticated() && this.location.path() === '/login') {
        history.go(1);
      } else if (this.auth.isAuthenticated() && this.location.path() === '/maintenance') {
        this.router.navigate(['../maintenance']);
      } else {
        this.commonservice.menuDisplaySelection(route_params);
      }
    });
  }

  ngOnInit() {
    if (this.auth.isAuthenticated() && this.location.path() === '/login') {
      this.router.navigate(['../maintenance']);
      return true;
    }
  }

}




/**
 * Global variable declaration for api route
 */
export const GlobalVariable = Object.freeze({
  BASE_API_URL : environment.apiHost,

  /***
   *  Dashboard
   *  */
  STATS_URL: "/admin/analytics",
  THIRTY_DAYS_OCCUPANCY_URL: "/admin/analytics/chart/thirty_day_occupancy",
  REVENUE_CHART_URL: "/admin/analytics/chart/revenue",
  THREE_MONTHS_CHECKIN_CHECKOUTS_URL:
  "/admin/analytics/chart/checkins_checkouts",
  THIRTY_DAYS_MONTHLY_OCCUPANCY_URL:
  "/admin/analytics/chart/three_months_occupancy",
  MAINTENANCE_CHART_URL: "/admin/analytics/chart/maintenance_tickets",
  GENDER_PIE_CHART_URL: "/admin/analytics/chart/gender_demograph",
  CHECKIN_CHECKOUT_MTD_URL: "/admin/analytics/chart/checkins_checkouts_mtd",
  TOP_TEN_UNIV_URL: "/admin/analytics/chart/top_ten_universities",
  REGIONS_CHART_URL: "/admin/analytics/chart/regions",
  COLLECTION_CHART_URL: "/admin/analytics/chart/collection",
  CHECKOUT_BOXPLOT_URL: "/admin/analytics/chart/median_tenure",
  BUILDINGS_URL: "/admin/buildings",
  BUILDING_PAYMENT_TYPES_URL: "/manager/building_payment_types",
  /**
   * Inspection
   */
  GET_INSPECTION: "/manager/Inspection-rooms/",
  /**
   * FEEDBACK Ticket status 
  */
  EDIT_TICKET_STATUS_URL: "/manager/tickets/edit/",
  CREATE_TICKET_STATUS_URL: "/manager/tickets/",
  DELETE_TICKET_STATUS:"/manager/tickets/delete/",
  SHOW_TICKET_STATUS:"/manager/tickets/show/",
  GET_FEEDBACK: "/manager/tickets/show/",
  /* (1) User Registration */
  REGISTER_URL : '/manager/register', //post
  LOGIN_URL : '/manager/login', //post
  FORGOTPASS_URL: '/forgotpass-otp', //post
  VERIFYOTP_URL: '/verify-otp', //post
  UPDATE_PASSWORD_URL: '/update-password', //post
  /* (2) User Profile */
  USER_PROFILE_ID : '/user-profile/', //put
  USER_PROFILE_IMAGE_UPLOAD : '/manager/resident-profiles/:residentId/update-profile-pic', //profile
  CHANGE_PASSWORD_URL: '/change-password',

  /* (3) MetaData */
  COUNTRY_LIST_URL : '/countries-list', //get
  DOCUMENT_NAME_LIST : '/document-name-list', //get
  DOCUMENT_TYPE_LIST : '/document-type-list', //get
  MANAGER_BUILDINGS: '/manager/buildings',
  BUILDING_ACCOUNTS_URL : '/building-accounts',
  ROOM_TYPES_URL : '/room-types',

  /* (4) Applicant */
  APPLICANTS : '/applicants/',
  FILTER_APPLICANTS_URL : '/manager/filter-applicants',//post
  ALLOCATEROOM : '/applicants/',//put
  SWAP_ROOM_URL: '/manager/swap-room',
  AVAIL_ROOM_URL : '/manager/get-rooms/',
  STUDENT_PROFILE_URL : '/manager/user-info/',
  STUDENT_APPLICANT_PROFILE_URL: '/manager/booking-info/',
  STUDENT_DOC_APPROVE_URL : '/manager/update-onboarding-document/',
  CHECK_IN_LIST_URL : '/manager/checkinlist/',
  CHECK_IN_LIST_FILTER_URL : '/manager/filter-check-in-users',
  STUDENT_BOOKING_HISTORY_URL : '/manager/booking-history/',
  RESIDENT_REFUND_HISTORY_URL : '/manager/refund-history/',
  STUDENT_RESIDENT_CHECK_LIST_URL: '/manager/check-list-meta',
  STUDENT_RESIDENT_CHECK_OUT_LIST_URL: '/manager/checkout-list-meta',
  CHECK_INVENTORY_INSPECTION_URL: '/manager/checkin-inventory-inspection',
  CHECK_DOCUMENT_INSPECTION_URL: '/manager/checkin-user-inspection',
  CHECK_OUT_LIST_URL : '/manager/checkout-list/',
  CHECK_OUT_LIST_FILTER_URL : '/manager/filter-check-out-users',
  INCOMPLETE_URL : '/manager/incomplete-bookings/',
  CHECKOUT_INVENTRY_INSPECTION_URL: '/manager/checkout-inventory-inspection',
  CHECKOUT_FEES_DETAILS_URL: '/manager/get-checkout-details/',//get
  VALIDATE_CHECK_IN_URL: '/manager/validate-checkin', //get
  BOOKING_CHECKOUT_UPDATE : '/manager/user-bookings', //post

  ON_BOARDING_DOCUMENT_ADD_URL: '/manager/onboarding-document', //get & post
  ON_BOARDING_DOCUMENT_UPDATE_URL: '/manager/onboarding-document/update/', //get & post

  CANCEL_BOOKING_INCOMPLETE_URL: '/manager/incomplete-bookings/',
  CANCEL_BOOKING_APPLICANT_URL: '/applicants/',


    // Link Generation For Payments

    GENERATE_LONG_LINK : '/manager/payments/generate-long-link',

/**
 * Feedback
*/


  ///api/v1/manager/check-list-meta

  /** OTP MANAGING API URL */
  SEND_OTP_URL: '/manager/checkin-otp',
  SUBMIT_OTP_URL: '/manager/verify-checkin-otp',
  CHECKIN_AGREEMENT_DOCUMENT_URL: '/manager/get-checkin-details/',
  CHECKIN_AGREEMENT_DOCUMENT_UPLOAD_URL: '/manager/checkin-agreement',
  CHECKOUT_SEND_OTP_URL: '/manager/checkout-otp',
  CHECKOUT_SUBMIT_OTP_URL: '/manager/verify-checkout-otp',
  REBOOK_OTP_URL: '/manager/send-rebook-otp',
  REBOOK_URL:'/manager/rebook',
  CHANGE_CHECKIN_DATE_URL: '/manager/update-checkin-date',

  /** CHECKOUT REFUND API URL */
  CHECKOUT_REFUND_URL: '/manager/checkout-refund',

  /** RESIDENT LIST API URL */
  RESIDENT_LIST_URL: '/manager/residents/',
  DOWNLOAD_RESIDENT_STATEMENTS_URL: '/manager/resident-accounts/download-statement',
  DOWNLOAD_INVOICE_URL: '/manager/resident-accounts/download-invoice',
  ACTIVITY_LOGS_URL:'/manager/activity-logs',
  DOWNLOAD_RESIDENT_AGREEMENT_URL: '/manager/user-bookings/download-agreement',

  /**TICKET LSIT */
  TICKET_LIST_URL: '/manager/tickets/',//get
  TICKET_STATUS_META_URL:'/ticket-status-meta',//get
  TICKET_DETAIL_URL: '/manager/get-ticket/',//get
  TICKET_STATUS_UPDATE_URL: '/manager/update-ticket-status',//get
  TICKET_CATEGORIES_META_URL:'/ticket-categories',//get
  TICKET_LOCATION_META_URL: '/ticket-locations',//get
  TICKET_USER_BY_BUILDING_URL: '/manager/residents-on-maintenance/',//get
  TICKET_CREATE_MANAGER_URL: '/manager/tickets',//get

  /** INVOICE TYPE */
  INVOICE_TYPE_URL: '/invoice-types',
  POST_CHARGES_URL: '/manager/post-charges',
  TICKET_FILTER_URL: '/manager/filter-tickets',//post
/**PAYMENTS LIST */
  PAYMENT_UNPAID_URL :'/manager/unpaid-invoices',//post
  PAYMENT_CLEAR_UNPAID_URL :'/manager/clear-unpaid-invoices',//post
  PAYMENT_METHOD_URL :'/payment-methods',//get
  PAYMENT_LOG_URL :'/manager/payment-logs/',//get
  ADD_WALLET_AMT_URL: '/manager/waiting-list-to-wallet',//post

  /**WAITING LIST */
  WAITING_LIST_URL :'/manager/waiting-list/',//get
  WAITING_PAYNOW_URL :'/manager/waiting-list',//post
  //WAITING_DELETE_URL :'/manager/waiting-list/',//post
  WAITING_INFO_URL :'/manager/waiting-list-info',//post also post
  WAITING_META_URL :'/get-metas',// post
  TRANSFER_TO_WALLET_URL :'/manager/transfer-to-wallet/:waitingListId',// put
  REFUND_OTP_REQUEST_URL :'/manager/send-refund-otp',// post
  REFUND_REQUEST_URL :'/manager/refunds',// post
  PRICING_PLANS_URL: '/manager/get-pricing-plans',
  PRICING_PLAN_URL: '/manager/get-pricing-plan',

  /**
   * Guest
   */
  GUEST_PROFILE_UPDATE_URL: '/manager/waiting-list/update-user-profile' ,//post
  GUEST_PROFILE_GET_URL: '/manager/waiting-list/get-user-details' ,//post
  GUEST_CONTACT_UPDATE_URL: '/manager/waiting-list/update-user-contact' ,//post

  /**
   * AVAILABLE ROOM
   */
  AVAILABLE_ROOM_GET_URL: '/manager/available-rooms/',//get
  AVAILABLE_ROOM_FILTER_URL: '/manager/filter-available-rooms',//get
  AVAILABLE_ROOM_30_DAYS_URL: '/manager/filter-available-rooms-thirty-days',//post
  DOWNLOAD_PAYMENT_RECEIPT_URL :'/manager/download-payment-receipt/', //get

  /* Notice Board Routes */
  NOTICES_URL: '/manager/notices',

  /* Electricity Tokens Routes */
  ELECTRICITY_TOKENS_URL: '/manager/electricity-tokens',

  /*Visitors logs URL */
  VISITOR_INVITES_URL: '/manager/vizmo-invites',
  VIZMO_EMPLOYEES_URL: '/manager/vizmo-employees',
  VISITOR_LOGS_URL: '/manager/vizmo-visitors'

});

