import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';
import { Router } from '@angular/router';


@Injectable()
export class MyHttpInterceptor {
  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService,
              private router:  Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler, refresh_token = null): Observable<HttpEvent<any>> {
    const token: string = this.storage.get('con_access_token');

    if (token) {
        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    }

    if (!request.headers.has('Content-Type')) {
        request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
              //this.spinner.stop();
          }
         // this.spinner.stop();
          return event;
      }),
      catchError(err => {
        if (err.status === 401) {
          this.storage.remove('con_access_token');
          this.storage.remove('user_details');
          this.storage.remove('contact_access');
          this.storage.remove('select_branch');
          this.storage.remove('checkinchecklist');
          this.storage.remove('student_id');
          this.router.navigate(['/login']);
        }
        return throwError(err);
      })
    );
  }
}
